.feature{
  float: left;
  width: 100%;
  background-color: #fff;
  margin:{
    bottom: 15px;
  }

  .feature-body{
    float: left;
    width: 100%;
    padding: {
      top: 10px;
      left: 15px;
      right: 15px;
      bottom: 15px;
    };
  }
  .feature-text{
    font-size: 14px;
    color: #333;
  }

  .feature-title{
    margin:{
      top: 0px;
    }
  }

  .feature-icon{
    color: #333;
  }
}

.block-left{
  .icon{
    width: 30%;
    display: table-cell;
    vertical-align: top;
  }
  .title{
    width: 70%;
    display: table-cell;
    vertical-align: top;
    padding:{
      top: 5px;
      left: 5px;
    }
    color: #333;
  }
}