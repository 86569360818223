.process{
  background-color: #fff;
  float: left;
  width: 100%;
  margin:{
    bottom: 15px;
  }

  .process-body{
    float: left;
    width: 100%;
    padding: {
      top: 10px;
      left: 15px;
      right: 15px;
      bottom: 15px;
    }
  }
  .process-text{
    font-size: 14px;
    color: #333;
  }
  .process-title{
    margin:{
      top: 0px;
    }
    color: #333;
  }
  .process-icon{
    color: #333;
  }
}