.slider{

  .main-slider__text{
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    font-size: 20px;
    text-align: left;
    padding:{
      left: 20px;
      right: 20px;
      bottom: 10px;
      top: 10px;
    }
    position: absolute;

    p{
      margin-bottom: 0px;
    }
  }

  .main-slider__action{
    position: absolute;
    left: 0;
    bottom: 60px;
    width: 100%;
    text-align: center;
  }

  .main-slider__link{
    color: #333;
    background-color: #fff;
    font-size: 16px;
    padding:{
      top: 7px;
      bottom: 7px;
      left: 20px;
      right: 20px;
    }
    border-radius: 3px;
    border: 1px solid #ddd;

    &:hover, &:focus{
      background-color: #f1f1f1;
      text-decoration: none;
    }
  }

  .swiper-slide__image{
    text-align: center;
    padding: 10px 10px;

    img{
      display: inline-block;
      max-width: 300px;
      max-height: 300px;
    }
  }
}

.swiper-pagination {
  .swiper-pagination-bullet-active {
    background: #000000;
  }
}