.dropdown-submenu {
  position: relative;
}

.dropdown-submenu {
  .dropdown-menu {
    top: 0;
    right: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-top-left-radius: 5px !important;
    -webkit-border-bottom-left-radius: 5px !important;
    -moz-border-radius-topleft: 5px !important;
    -moz-border-radius-bottomleft: 5px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-top-right-radius: 0px !important;
  }
}

.dropdown-submenu:hover>.dropdown-menu {
  display: block;
}

.dropdown-submenu>a:before {
  display: block;
  content: "\f0d9";
  position: absolute;
  left: 5px;
  top: 7px;
  font: normal normal normal 14px/1 FontAwesome;
  box-shadow: 0 4px 5px rgba(181, 181, 181, 0.175);
}

.dropdown-submenu:hover>a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}