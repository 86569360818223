.content{
  background-color: #fff;
  padding: {
    top: 15px;
    bottom: 15px;
  }
  padding: 15px;

  h1{
    margin-top: 0px;
  }
}