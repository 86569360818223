.products{
  margin:{
    top: 20px;
  }
}

.best-product{
  z-index: 3;
  position: relative;
  overflow: hidden;

  &:before{
    content: 'BEST';
    display: block;
    font-size: 12px;
    position: absolute;
    right: -30px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(40deg);
    background-color: #ffd400;
    width: 105px;
    padding:{
      top: 3px;
    }
  }
}

.product-card{
  background-color: #fff;
  padding: 10px;
  float: left;
  width: 100%;
  margin: {
    bottom: 15px;
  }

  .product-price{
    font-size: 20px;
    margin-bottom: 10px;
    background-color: #f1f1f1;
    font-weight: bold;
  }

  .product-title{
    font-size: 18px;

    margin:{
      top: 0px;
      bottom: 10px;
    }
  }

  .product-properties{
    margin-bottom: 10px;

    ul{
      margin: 0px;
      padding: 0px;

      li{
        list-style: none;
        padding: {
          top: 2px;
          bottom: 2px;
        }
      }
    }
  }

  .product-quantity{
    font-size: 30px;
    background-color: #fff;
    padding: {
      top: 5px;
      bottom: 5px;
    }
  }

  border: 1px solid #ddd;
}
