footer {
  border-top: 1px solid #ddd;

  .footer-copy {
    padding: {
      top: 10px;
      bottom: 10px;
    }
    font-size: 13px;
  }

  .footer-lists{
    margin: 0px;
    padding: 0px;
  }

  .footer-lists__item{
    list-style: none;
    font-size: 14px;
    padding: {
      bottom: 3px;
    }
  }

  .footer-lists__title{
    padding-bottom: 10px;
    font-size: 18px;
  }

  .footer-container{
    padding: {
      top: 15px;
      bottom: 15px;
    }
  }

  .footer-links{
    margin: 0px;
    padding: 0px;
    text-align: right;

    li{
      list-style: none;
      display: inline-block;
      margin:{
        left: 15px;
      };
    }
  }
}