@import "footer";
@import "navbar";
@import "feature";
@import "reviews";
@import "steps";
@import "content";
@import "contact-us";
@import "product";
@import "dropdown";
@import "cart";
@import "slider";
@import "order";
@import "section";

html, body{
  height: 100%;
  min-height:100%;
}

body{
  background-color: #f8f9fa;
  font-size: 14px;
}
.bg-white{
  background-color: #fff;
  float: left;
  width: 100%;
}
.bg-gray-light{
  background: #eee;
}
.bold{
  font-weight: 500;
}
.content-block{
  min-height: 800px;
}
.border-bottom{
  border-bottom: 1px solid #ddd;
}

.main-slider{
  padding-bottom: 0px;

  .main-slide{
    min-height: 300px;
    background-size: cover;
    background-position: center center;
  }
}

.page-title{
  margin: {
    top: 0px;
    bottom: 15px;
  }
}

@import "media";

// Colors
@import "colors";
