
@media (max-width: 991px){
  footer{
    .footer-links{
      text-align: left;
      li{
        margin: {
          left: 0px;
        }
        width: 100%;
        padding: {
          top: 3px;
          bottom: 3px;
        }
      }
    }
  }
  .main-slider{
    padding: {
      bottom: 50px;
    };
  }
}

@media (max-width: 768px) {
  .slider{
    .main-slider__text {
      font-size: 14px;
    }
  }
  .navbar-brand{
    padding-left: 15px;
  }
  .navbar-white {
    .dropdown-menu {
      width: 100%;
      position: relative;
      display: block !important;
      box-shadow: none;
      border-left: none;
      border-bottom: none;
      border-right: none;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 0px;
      border-top: none;
      padding: 0px;
      margin: 0px;

      li{
        a{
          padding: 5px 15px;
        }
      }
    }
    .dropdown-toggle {
      border-top: 1px solid #ddd;
    }

    .navbar-collapse{
      border-top: 1px solid #ddd;
    }
  }

  .dropdown-submenu {
    .dropdown-menu{
      border-left: none;
      border-bottom: none;
      border-right: none;
      margin-top: 0px;

      li {
        a {
          padding: 5px 15px;
          line-height: 20px;
          display: block;
        }
      }
    }
    .dropdown-toggle {
      &:before {
        display: none;
      }
      &:after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        border-top: 4px dashed;
        border-top: 4px solid\9;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
      }
    }
  }
}
