.navbar-brand{
  padding: 0px;
  img{
    max-height: 51px;
  }
  margin:{
    top: -1px;
    left: 0px!important;
  }
  line-height: 51px;
  vertical-align: middle;
  position: relative;
}
.navbar-white{
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  border-radius: 0px;

  .navbar-nav{
    li{
      a{
        color: #333;
      }
    }
  }

  margin-bottom: 0px;

  .navbar-brand{
    color: #333;
  }

  .dropdown-menu{
    border-top: 1px solid #fff;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .navbar-toggle .icon-bar{
    background-color: #333;
  }

}