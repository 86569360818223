.order {
  margin:{
    bottom: 15px;
  };
  .order-block {
    padding: 10px;

    .order-title {
      margin: {
        top: 2px;
      }
    }

    .table {
      margin: {
        bottom: 0px;
      }
    }
  }
}