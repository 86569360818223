section{
  padding: {
    top: 15px;
    bottom: 15px;
  }
  .section-header{
    margin: {
      bottom: 15px;
    }
    h2{
      margin-bottom: 20px;
    }
  }
  .section-description{
    padding: 10px;
  }
}

.min-height{
  min-height: calc(100% - 90px);
}