.cart{
  padding: 10px;
  float: left;
  width: 100%;

  margin:{
    bottom: 15px;
  }

  .table>thead>tr>th{
    border-bottom: 1px solid #ddd;
  }

  .table{
    margin:{
      bottom: 0px;
    };
  }
}
.cart-total{
  padding:{
    top: 5px;
    bottom: 5px;
  }
}
