.swiper-container {
  width: 100%;
  height: 100%;
  padding: {
    bottom: 55px;
  }
}
.swiper-pagination-bullet{
  width: 15px;
  height: 15px;
}
.swiper-slide {
  padding: {
    top: 15px;
  }
}
.review-author{
  font-size: 18px;
  font-weight: bold;
}
.review-text{
  padding: {
    bottom: 10px;
    top: 10px;
  }
  margin: {
    top: 15px;
  }
  font-size: 16px;
  background-color: #fff;
  border-radius: 15px;
}
.review-image{
  display: inline-block;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center center;
  box-shadow: 0px 2px 1px rgba(8, 8, 8, 0.14) inset;
}