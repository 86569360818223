$color_body_bg: #000;
$color_navbar_bg: #f8f9fa;
$color_footer_bg: #f0f0f0;
$color_icons: #338dbc;
$color_link: #338dbc;
$color_button_default: #ffffff;
$color_button_primary: #338dbc;

.navbar{
  background: $color_navbar_bg!important;
}


/// White
/*
.navbar-nav{
  li{
    a{
      color: #fff!important;

      &:hover, &:focus{
        background-color: rgba(255, 255, 255, 0.14)!important;
      }
    }
  }
  .dropdown-menu{
    li{
      a{
        color: #333!important;
      }
    }
  }
}
.navbar-brand{
  color: #fff!important;
}
*/
.navbar-nav{
  li{
    a{
      color: #333!important;

      &:hover, &:focus{
        background-color: rgba(0, 0, 0, 0.04) !important;
      }
    }
  }
  .dropdown-menu{
    li{
      a{
        color: #333!important;
      }
    }
  }
}
.navbar-brand{
  color: #333!important;
}